import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { VideoRequest } from 'src/app/models/videos/video-request.model';
import { Video } from 'src/app/models/videos/videos.model';
import { Wrapper } from 'src/app/models/wrapper/wrapper.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VideosService {

  private VIDEOS_PATH = '';
  private REST_API_SERVER = '';
  private VIDEOS_COUNT = '';

  constructor(private ressourcePath: GlobalURLs,
    private http: HttpClient) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  public getVideos(request:VideoRequest): Observable<Wrapper<Video>> {
    this.VIDEOS_PATH = this.ressourcePath.getVideos();
    
    let params = new HttpParams();
    if (request.start) {
      params = params.append("page", request.start);
    }
    if (request.limit) {
      params = params.append("pageSize", request.limit);
    }
    if (request.sectorId) {
      params = params.append("sectorId", request.sectorId);
    }
    if (request.regionalBranchIds && request.regionalBranchIds.length>0) {
      params = params.appendAll({"regionalBranchIds": request.regionalBranchIds});
    }
    if (request.tagIds && request.tagIds.length>0) {
      params = params.appendAll({ "tagIds": request.tagIds });
    }
    if (request.isActusRessources) {
      params = params.append("isActusRessources", request.isActusRessources);
    }
    if (request.excludeIds && request.excludeIds.length>0) {
      params = params.appendAll({"excludeIds":  request.excludeIds});
    }
    return this.http.get<Wrapper<Video>>(this.REST_API_SERVER + this.VIDEOS_PATH, {params});
  }

  public getVideosCount(isActusRessources?:boolean,tagIds?:number[]): Observable<number> {
    let params = new HttpParams();
    if (isActusRessources) {
      params = params.append("isActusRessources", isActusRessources);
    }
    if (tagIds) {
      params = params.appendAll({ "tagIds": tagIds });
    }
    this.VIDEOS_COUNT = this.ressourcePath.getVideosCount();
    return this.http.get<number>(this.REST_API_SERVER + this.VIDEOS_COUNT, {params});
  }

  public getVideosById(id: number): Observable<Video> {
    this.VIDEOS_PATH = this.ressourcePath.getVideoId(id);
    return this.http.get<Video>(this.REST_API_SERVER + this.VIDEOS_PATH);
  }

  public updateVideoLikes(id: number, likes: number): Observable<any> {
    let params = new HttpParams();
    params = params.appendAll({ "like": likes });
    return this.http.put(this.REST_API_SERVER + this.VIDEOS_PATH + "?like="+likes, { params});
  }
 
}
