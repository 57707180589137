import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as Hammer from 'hammerjs';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { MarkdownModule } from 'ngx-markdown';
import { BasicElementsModule } from '../components/basic-elements/basic-elements.module';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CollapseMenuComponent } from './components/collapse-menu/collapse-menu.component';
import { IconComponent } from './components/icon/icon.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { NewCarouselItemComponent } from './components/new-carousel/new-carousel-item/new-carousel-item.component';
import { NewCarouselComponent } from './components/new-carousel/new-carousel.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { PageSectionActualitiesComponent } from './components/page-section-actualities/page-section-actualities.component';
import { PageSectionBpiComponent } from './components/page-section-bpi/page-section-bpi.component';
import { PartnerSpaceFooterComponent } from './components/partner-space-footer/partner-space-footer.component';
import { PartnerSpaceHeaderComponent } from './components/partner-space-header/partner-space-header.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs/tabs.component';
import { ariaCurrentDirective } from './directives/aria-current.directive';
import { BreadcrumbItemDirective } from './directives/breadcrumb-item.directive';
import { CarouselSlideDirective } from './directives/carousel-slide.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import {
  RedirectDirective,
  RedirectWithHrefDirective
} from './directives/redirect.directive';
import { StepsItemDirective } from './directives/steps-item.directive';
import { TagBackgroundColorDirective } from './directives/tag-background-color';
import { AverageCountPipe } from './pipes/average-count/average-count.pipe';
import { BoldStringPipe } from './pipes/bold-string/bold-string.pipe';
import { NbDaysElapsedPipe } from './pipes/date/date-format.pipe';
import { DateRemainingdaysPipe } from './pipes/date/date-remainingdays.pipe';
import { SafePipe } from './pipes/dom-sanitizer/safe.pipe';
import { FormatUrlPipe } from './pipes/format-url.pipe';
import { UnderscoreRemoverPipe } from './pipes/underscore-remover.pipe';
import { NavbarPartnerSpaceComponent } from './components/navbar-partner-space/navbar-partner-space.component';
import { NavbarPublicSpaceComponent } from './components/v-2024/navbar-public-space/navbar-public-space.component';
import { SearchBarMenuComponent} from './components/v-2024/search-bar/search-bar.component';
import { FooterPublicSpaceComponent } from './components/v-2024/footer-public-space/footer-public-space.component';
import { FormatLikesPipe } from './pipes/format-likes.pipe';

export class MyHammerConfig extends HammerGestureConfig {
  override overrides = {
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL }
  } as any;
}
@NgModule({
  declarations: [
    // components
    IconComponent,
    MainHeaderComponent,
    MainFooterComponent,
    PageSectionActualitiesComponent,
    PageSectionBpiComponent,
    CarouselComponent,
    ScrollToTopComponent,
    NewsletterComponent,
    TabsComponent,
    TabComponent,
    PartnerSpaceHeaderComponent,
    PartnerSpaceFooterComponent,
    CollapseMenuComponent,
    NavbarPartnerSpaceComponent,
    NavbarPublicSpaceComponent,
    FooterPublicSpaceComponent,

    //Search bar
    SearchBarComponent,
    SearchBarMenuComponent,

    // directives
    RedirectDirective,
    RedirectWithHrefDirective,
    ClickOutsideDirective,
    BreadcrumbItemDirective,
    CarouselSlideDirective,
    ariaCurrentDirective,
    TagBackgroundColorDirective,
    StepsItemDirective,

    // pipes
    NbDaysElapsedPipe,
    FormatUrlPipe,
    DateRemainingdaysPipe,
    UnderscoreRemoverPipe,
    SafePipe,
    AverageCountPipe,
    BoldStringPipe,
    FormatLikesPipe,
    NewCarouselComponent,
    NewCarouselItemComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BasicElementsModule,
    ReactiveFormsModule,
    
    RouterModule,
    MarkdownModule.forChild(),
    FeatureToggleModule,
    TranslateModule
  ],

  exports: [
    // components
    IconComponent,
    MainHeaderComponent,
    MainFooterComponent,
    PageSectionActualitiesComponent,
    PageSectionBpiComponent,
    CarouselComponent,
    ScrollToTopComponent,
    NewsletterComponent,
    TabsComponent,
    TabComponent,
    PartnerSpaceHeaderComponent,
    PartnerSpaceFooterComponent,
    CollapseMenuComponent,
    NavbarPartnerSpaceComponent,
    NavbarPublicSpaceComponent,
    FooterPublicSpaceComponent,

    //Search bar
    SearchBarComponent,
    SearchBarMenuComponent,

    // directives
    RedirectDirective,
    RedirectWithHrefDirective,
    ClickOutsideDirective,
    BreadcrumbItemDirective,
    CarouselSlideDirective,
    ariaCurrentDirective,
    TagBackgroundColorDirective,
    StepsItemDirective,

    // pipes
    NbDaysElapsedPipe,
    FormatUrlPipe,
    DateRemainingdaysPipe,
    UnderscoreRemoverPipe,
    SafePipe,
    AverageCountPipe,
    BoldStringPipe,
    FormatLikesPipe,
    NewCarouselComponent,
    NewCarouselItemComponent,

    // modules
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule
  ],
  providers:[
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    }
  ]
})
export class SharedModule { }
