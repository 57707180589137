import { Injectable } from '@angular/core';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Forum } from 'src/app/models/forums/forum.model';
import { Wrapper } from 'src/app/models/wrapper/wrapper.model';
import { ForumRequest } from 'src/app/models/forums/forum-request.model';


@Injectable({
  providedIn: 'root'
})
export class ForumService {
  private restApiServer = '';
  private forums = '';
  private forumsCount = '';
  
  constructor(private http: HttpClient, private ressourcePath: GlobalURLs) {
    this.restApiServer = environment.apiUrl;
  }

  public getForums(request: ForumRequest): Observable<Wrapper<Forum>> {

    let params = new HttpParams()

    if (request.start) {
      params = params.append("page", request.start);
    }
    if (request.limit) {
      params = params.append("pageSize", request.limit);
    }
    if (request.tagIds && request.tagIds.length>0) {
      params = params.appendAll({ "tagIds": request.tagIds });
    }
    if (request.excludeIds && request.excludeIds.length>0) {
      params = params.appendAll({"excludeIds":  request.excludeIds});
    }

    this.forums = this.ressourcePath.getForums();
    
    return this.http.get<Wrapper<Forum>>(this.restApiServer + this.forums, { params });
  }

  public getForumById(id: number): Observable<Forum> {
    this.forums = this.ressourcePath.getForumById(id);
    return this.http.get<Forum>(this.restApiServer + this.forums);;
  }

  public updateForumLikes(id: number, likes: number): Observable<any> {
    let params = new HttpParams();
    params = params.appendAll({ "like": likes });
    return this.http.put(this.restApiServer + this.forums + "?like="+likes, { params});
  }

  public getForumsCount(tagIds?: number[]): Observable<number> {
    let params = new HttpParams();
    if (tagIds && tagIds.length>0) {
      params = params.appendAll({ "tagIds": tagIds });
    }
    this.forumsCount = this.ressourcePath.getForumsCount();
    return this.http.get<number>(this.restApiServer + this.forumsCount, { params });
  }

}
