<feature-toggle-provider [features]="featureToggleData">
    <router-outlet></router-outlet>
    <!-- Temporary addition of the event registration popup -->
    <dep-popup-event *ngIf="!isEP && popup && popup.url!=null" [imageUrl]="popup.image"
        [btnLibelle]="popup.buttonName" [btnUrl]="popup.url" source="popupPUB">
    </dep-popup-event>
    <!---->
    
    <!-- TOASTER : error -->
    <dep-toaster [status]="'success'" [toasterTitle]="'Votre Demande est en cours de traitement!'"
    [toasterText]="'Une demande d\'accès est déjà en cours de traitement, elle sera traitée sous un délai de 24h. Merci de votre compréhension.'" [show]="this.partneSpaceRequestInProcess$|async"
    (closeToaster)="closeToaster()">
    </dep-toaster>

</feature-toggle-provider>