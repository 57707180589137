import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ACTUALITIESTYPES } from 'src/app/mocks/actualities-types/mock-actualities-types';
import { NEWSLETTER_TYPES } from 'src/app/mocks/newsletter-type/mock-newsletter.model';
import { Study } from 'src/app/models/studies/study.model';
import { Suggestion, TypeArticle } from 'src/app/models/suggestions/suggestion.model';
import { CookieNewsletterService } from 'src/app/services/cookie-newsletter/cookie-newsletter.service';
import { StudyService } from 'src/app/services/studies/study.service';
import { SuggestionService } from 'src/app/services/suggestions/suggestion.service';
import { ServerErrorInterceptorService } from 'src/app/shared/services/servererrorinterceptor.service';



@Component({
  selector: 'study-detail',
  templateUrl: './study-detail.component.html',
  styleUrls: ['./study-detail.component.scss']
})
export class StudyDetailComponent implements OnInit, OnDestroy {

  @ViewChild('myElem') MyProp: ElementRef;

  // Input from url
  @Input() public selectedStudyId: number;

  public studiesTypes = ACTUALITIESTYPES;

  public study: Study;
  public isOpen: boolean;
  public hasLiked: boolean = false;
  public suggestions:Suggestion[] = [];

  private title: string;
  private metaDescription: string;

  public newsletter = NEWSLETTER_TYPES[1] ;
  public isOpenPopup : boolean = false
  public documentExist: boolean = false;


  constructor(private meta: Meta,
              private titleService: Title,
              private renderer: Renderer2,
              @Inject(DOCUMENT) document: any,
              @Inject(StudyService) private studyService: StudyService,
              private suggestionService: SuggestionService,
              private activateRoute: ActivatedRoute,
              private cookieNewsletterService: CookieNewsletterService,
              private serverErrorInterceptor:ServerErrorInterceptorService) {
    this.renderer.addClass(document.body, 'v2021');
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(params => {
      this.selectedStudyId = params.selectedStudy.split('-')[0];
      this.getStudyById();
    });
    this.isOpen = this.cookieNewsletterService.checkCookie(this.newsletter.cookieName);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'v2021');
  }

  /**
   * Get study by id
   */
  public getStudyById(): void {
    this.studyService.getStudyById(this.selectedStudyId).subscribe(
      (data) => {
        this.study = data;
        this.title = data.altTitle;
        this.metaDescription = data.altMetaDescription;

        this.titleService.setTitle(this.title);
        this.meta.addTag({
          name: 'description',
          content: this.metaDescription
        });
        this.study.contents.forEach(element => {
          if(element.documentId){
            this.documentExist = true;
          }
        });
        this.loadSuggestions();
      },
      (error) => {
        this.serverErrorInterceptor.intercept(error)
      }
    );
  }

  public scroll(): void{
    document.getElementById('myElem').scrollIntoView({behavior : 'smooth', block: 'center'});
  }

  closeNewsletter($event: any): void {
    this.isOpen = $event;
  }

  likeStudy(): void {
    if (this.isLiked()) return;

    const currentLikes = Number(this.study.like) || 0;
    const updatedLikes = currentLikes + 1;
    this.study.like = updatedLikes.toString();
    this.hasLiked = true;

    const likedStudies = JSON.parse(localStorage.getItem('likedStudies') || '[]');
    likedStudies.push({ id: this.study.id, type: 'study' });
    localStorage.setItem('likedStudies', JSON.stringify(likedStudies));

    this.studyService.updateStudyLikes(this.study.id, updatedLikes).subscribe();
  }
  isLiked(): boolean {
    const likedStudies = JSON.parse(localStorage.getItem('likedStudies') || '[]');
    return likedStudies.some((item: any) => item.id === this.study?.id && item.type === 'study');
  }

  private loadSuggestions(): void {
    this.suggestions = this.study.suggestions;
    this.suggestions.forEach(suggestion => {
      suggestion.typeArticle = TypeArticle.SUGGESTION;
    });
    
    this.suggestionService.setupSuggestions(this.suggestions,this.study,TypeArticle.ETUDE).subscribe(
      (suggestions) => {
        this.suggestions = suggestions;
      },
      (error) => {
        console.error('Error fetching suggestions', error);
      }
    );
  }

  setPopupStatus(isOpen : boolean): void {
    this.isOpenPopup = isOpen;
  }

}
