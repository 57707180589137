import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Habilitation, User } from "src/app/models/user/user";
import { FavoriteService } from "src/app/services/favorites/favorite.service";
import { environment } from 'src/environments/environment';
import { SharedService } from "../shared/shared.service";
import { MemberRequest } from 'src/app/models/member-request/member-request.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private REST_API_SERVER = '';
    private USERS_URL = '/users'
    private USER_INFO = '/userInfo';
    private ACCEPT_CGU = '/validate-cgu';
    private SET_FIRST_CONNECTION = '/validate-first-connection';
    private MEMBER_REQUEST = '/memberRequest';
    private REQUEST_HABILITATION = '/requestHabilitation';
    private DELETION_REQUEST = '/deletionRequest';

    public triggerFirstConnection = true;

    public userSubject = new BehaviorSubject<User>(null);
    public idTokenSubject = new BehaviorSubject<string>("");
    public user: User = new User();
    public percentageBehaviour = new BehaviorSubject<number>(0);
    public percentage$ = this.percentageBehaviour.asObservable();

    public partneSpaceRequestInProcessBehavior = new BehaviorSubject<boolean>(false);
    public partneSpaceRequestInProcess$ = this.partneSpaceRequestInProcessBehavior.asObservable();

    constructor(
        public oidcSecurityService: OidcSecurityService,
        private router: Router,
        private http: HttpClient,
        private favoriteService: FavoriteService,
        private sharedService: SharedService
    ) {
        this.REST_API_SERVER = environment.apiUrl;


    }

    fetchUserData(): User {
        this.getUserData().subscribe(
            result => {
                this.refreshUser(result);
            });

        return this.user;
    }



    getUserData(): Observable<User> {
        const httpOptions = this.sharedService.getSecureHeaders();
        if (sessionStorage.getItem('user')) {
            this.user = Object.assign(new User(), JSON.parse(sessionStorage.getItem('user')));
            return of(this.user)
        }

        return this.http.get<User>(this.REST_API_SERVER + this.USERS_URL + this.USER_INFO, httpOptions).pipe(map(u => Object.assign(new User(), u)));
    }

    updateUserCGUValidation(): void {
        const httpOptions = this.sharedService.getSecureHeaders();
        this.http.put<User>(this.REST_API_SERVER + this.USERS_URL + this.ACCEPT_CGU, {}, httpOptions).subscribe((user) => {
            this.user = user;
            this.userSubject.next(this.user);
            sessionStorage.setItem("user", JSON.stringify(this.user));
        });
    }

    setFirstConnection(): void {
        const httpOptions = this.sharedService.getSecureHeaders();
        this.http.put<User>(this.REST_API_SERVER + this.USERS_URL + this.SET_FIRST_CONNECTION, {}, httpOptions).subscribe((user) => {
            this.user = user;
            this.user.firstConnection = user.firstConnection;
            this.userSubject.next(this.user);
            sessionStorage.setItem("user", JSON.stringify(this.user));
        });
    }

    updateUser(user: User): Observable<User> {
        const httpOptions = this.sharedService.getSecureHeaders();
        return this.http.put<User>(this.REST_API_SERVER + this.USERS_URL, user, httpOptions)
    }

    updateUserProfile(user: User,photo?: File): Observable<User> {
        const httpOptions = this.sharedService.getSecureHeaders();
        
        const formData = new FormData();
        if(photo){
            formData.append('photo', photo);
        }

        formData.append('userInfo', new Blob([JSON.stringify(user)], {type: "application/json"}));

        return this.http.put<User>(this.REST_API_SERVER + this.USERS_URL + "/profile", formData, httpOptions)
    }


    memberRequest(memberRequest: MemberRequest): Observable<User> {
        const httpOptions = this.sharedService.getSecureHeaders();
        return this.http.put<User>(this.REST_API_SERVER + this.USERS_URL + this.MEMBER_REQUEST, memberRequest, httpOptions)
    }

    requestHabilitation(habilitationNames: string[]): Observable<User> {
        let params = new HttpParams();
        params = params.appendAll({ "habilitationName": habilitationNames });
        const httpOptions = this.sharedService.getSecureHeaders(null, params);
        return this.http.put<User>(this.REST_API_SERVER + this.USERS_URL + this.REQUEST_HABILITATION, habilitationNames, httpOptions)
    }

    deletionRequest(departureReason: string): Observable<User> {
        let params = new HttpParams();
        params = params.append("departureReason", departureReason);
        const httpOptions = this.sharedService.getSecureHeaders(null, params);
        return this.http.put<User>(this.REST_API_SERVER + this.USERS_URL + this.DELETION_REQUEST, departureReason, httpOptions)
    }


    login(configId?: string) {
        this.oidcSecurityService.authorize(configId);
    }


    logout() {
        const config: string = sessionStorage.getItem('configId');

        this.oidcSecurityService.getIdToken(config).subscribe(
            result => {
                this.oidcSecurityService.logoffAndRevokeTokens(config, { customParams: { 'id_token_hint': result } }).subscribe(
                    (result) => {
                        // console.log("logout");
                        this.user = null;
                        sessionStorage.removeItem('redirect');
                        sessionStorage.removeItem('validLogin');
                        sessionStorage.removeItem('user');
                        sessionStorage.removeItem('configId');
                        sessionStorage.removeItem('favorites');
                    }
                );
            }
        );

        this.oidcSecurityService.logoffLocalMultiple();
        if (config == 'isam')
            this.navigateToHomePage();
    }

    refuseAccess() {
        sessionStorage.setItem('popup', "true");
        sessionStorage.removeItem('redirect');
        this.navigateToHomePage()
    }

    firstConnection() {
        this.getUserData().subscribe(
            reponse => {
                this.favoriteService.init(reponse);
            }
        )
    }

    public navigateToHomePage() {
        this.router.navigate([""]);
    }

    public navigateToProfilePage() {
        this.router.navigate(["mon-espace-partenaire/profil.html"]);
    }

    public setRedirect(url: string): void {
        sessionStorage.setItem('redirect', url);
    }

    public getRedirect(): string {
        let redirect = sessionStorage.getItem('redirect');
        sessionStorage.removeItem('redirect');
        return redirect;
    }

    public refreshUser(user: User):void{
        this.user = Object.assign(new User(), user);        
        this.userSubject.next(this.user);
        sessionStorage.setItem("user",JSON.stringify(this.user));
        this.updatePercentage(this.user);
    }


    public updatePercentage(user: User): void{

        let numberOfField = 0;
        let percentage = 50;
       
        if(user.phone){
            numberOfField++;
        }
        if(user.linkedinUrl){
            numberOfField++;
        }
        if(user.job){
            numberOfField++;
        }
        if(user.interventionLocations?.length>0){
            numberOfField++;
        }

        switch (numberOfField) {
            case 1:
                percentage = 60;
                break;
            case 2:
                percentage = 75;
                break;
            case 3:
                percentage = 80;
                break;
            case 4:
                percentage = 100;
                break;
        
            default:
                break;
        }
    
        this.percentageBehaviour.next(Math.round(percentage));
    
      }
}