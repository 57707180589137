import { DOCUMENT } from '@angular/common';
import {  Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ACTUALITIESTYPES } from 'src/app/mocks/actualities-types/mock-actualities-types';
import { NEWSLETTER_TYPES } from 'src/app/mocks/newsletter-type/mock-newsletter.model';
import { Actuality } from 'src/app/models/actualities/actuality.model';
import { JobOfferRequest } from 'src/app/models/job-offers/job-offer-request.model';
import { JobOfferType } from 'src/app/models/job-offers/job-offer-type.enum';
import { JobOffer } from 'src/app/models/job-offers/job-offer.model';
import { Suggestion, TypeArticle } from 'src/app/models/suggestions/suggestion.model';
import { ActualityService } from 'src/app/services/actualities/actuality.service';
import { CookieNewsletterService } from 'src/app/services/cookie-newsletter/cookie-newsletter.service';
import { JobOfferService } from 'src/app/services/job-offers/job-offers.service';
import { SuggestionService } from 'src/app/services/suggestions/suggestion.service';
import { ServerErrorInterceptorService } from 'src/app/shared/services/servererrorinterceptor.service';
import { SharedService } from 'src/app/shared/services/shared/shared.service';


@Component({
  selector: 'dep-actuality-detail',
  templateUrl: './actuality-detail.component.html',
  styleUrls: ['./actuality-detail.component.scss']
})
export class ActualityDetailComponent implements OnInit, OnDestroy {
  @Input() public selectedActualityId: number;

  actualitiesTypes = ACTUALITIESTYPES;
  clipboardCopySuccess = false;
  clipboardmessageDuration = 4000;

  public actuality: Actuality;
  public isOpen: boolean;
  public hasLiked: boolean = false;

  private title: string;
  private metaDescription: string;
  public newsletter = NEWSLETTER_TYPES[1];
  public fullUrl: string;
  public offer : JobOffer;
  public offers : JobOffer[] = [];
  private jobOfferRequest: JobOfferRequest = new JobOfferRequest();

  public suggestions:Suggestion[] = [];
  public isOpenPopup : boolean = false;

  constructor(private meta: Meta,
    private titleService: Title,
    private renderer: Renderer2,
    private router: Router,
    @Inject(DOCUMENT) document: any,
    @Inject(ActualityService) private actualityService: ActualityService,
    private suggestionService: SuggestionService,
    private jobOfferService: JobOfferService,
    private activateRoute: ActivatedRoute,
    private serverErrorInterceptorService: ServerErrorInterceptorService,
    private cookieNewsletterService: CookieNewsletterService,
    private sharedService:SharedService) {
    this.renderer.addClass(document.body, 'v2021');
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(params => {
      this.selectedActualityId = params.selectedActuality.split('-')[0];
      if(this.selectedActualityId.toString() !== '.'){
      this.getActualityById();
      }else{
        this.router.navigateByUrl( '/page-404/.');
      }
    });
    this.isOpen = this.cookieNewsletterService.checkCookie(this.newsletter.cookieName);
    this.fullUrl = window.location.href;

  }


  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'v2021');
    this.meta.removeTag("name='twitter:card'")
    this.meta.removeTag("name='twitter:title'")
    this.meta.removeTag("name='twitter:image'")
    this.meta.removeTag("name='twitter:image:src'")
    this.meta.removeTag("property='og:type'")
    this.meta.removeTag("property='og:url'")
    this.meta.removeTag("property='og:title'")
    this.meta.removeTag("property='og:image'")
  }

  public copyUrl(): void {
    navigator.clipboard.writeText(document.location.href)
      .then(() => {
        this.clipboardCopySuccess = true;
        setTimeout(() => {
          this.clipboardCopySuccess = false;
        }, this.clipboardmessageDuration);
      })
      .catch(() => this.clipboardCopySuccess = false);
  }

  /**
   * Get actuality by id
   */
  private getActualityById(): void {
    this.actualityService.getActualityById(this.selectedActualityId).subscribe(
      (data) => {
        this.actuality = data;
        this.title = data.altTitle;
        this.metaDescription = data.altMetaDescription;
        let taleezTags = this.getTaleeTags();
        if(taleezTags.length > 0){
          this.jobOfferRequest.tags = taleezTags;
          this.getJoboffers();
        }


      },
      (error) => {
        this.serverErrorInterceptorService.intercept(error);
      },
      () => {
        this.loadSuggestions();
        const imagePreview = this.sharedService.formatPreviewImage(this.actuality.imageUrl);
        this.titleService.setTitle(this.title);
        this.meta.addTag({
          name: 'description',
          content: this.metaDescription
        });
        this.meta.addTags([
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:title', content: this.actuality.title },
          { name: 'twitter:image', content: imagePreview },
          { name: 'twitter:image:src', content: imagePreview },
          { property: 'og:type', content: 'article' },
          { property: 'og:url', content: this.fullUrl },
          { property: 'og:title', content: this.actuality.title },
          { property: 'og:image', content: imagePreview }
        ]);
      }
    );
  }

  getTaleeTags(): string[]{
    return this.actuality.tags.flatMap(tag => tag.tagTaleez).map(tagtaleez => tagtaleez.name);
  }

  private initJobOfferRequest(): void {
    this.jobOfferRequest.pageNumber = 0;
    this.jobOfferRequest.pageSize = 4;
    this.jobOfferRequest.status = 'PUBLISHED';
    this.jobOfferRequest.jobOfferType = JobOfferType.TANDEM;
  }

  private getJoboffers() {
    this.initJobOfferRequest();
    this.jobOfferService.getJobOffers(this.jobOfferRequest).subscribe(
      results => {
        this.offers = this.offers.concat(results.jobOffers);
        this.offer = this.offers[0];
      },
      error => console.error(error)
    );
    this.jobOfferRequest.jobOfferType = JobOfferType.ADVISOR;
    this.jobOfferService.getJobOffers(this.jobOfferRequest).subscribe(
      results => {
        this.offers = this.offers.concat(results.jobOffers);
        this.offer = this.offers[0];
      },
      error => console.error(error)
    )
  }


  likeActuality(): void {
    if (this.isLiked()) return;

    const currentLikes = Number(this.actuality.like) || 0;
    const updatedLikes = currentLikes + 1;
    this.actuality.like = updatedLikes.toString();
    this.hasLiked = true;

    const likedActualities = JSON.parse(localStorage.getItem('likedActualities') || '[]');
    likedActualities.push({ id: this.actuality.id, type: 'actuality' });
    localStorage.setItem('likedActualities', JSON.stringify(likedActualities));

    this.actualityService.updateActualityLikes(this.actuality.id, updatedLikes).subscribe();
  }
  isLiked(): boolean {
    const likedActualities = JSON.parse(localStorage.getItem('likedActualities') || '[]');
    return likedActualities.some((item: any) => item.id === this.actuality?.id && item.type === 'actuality');
  }

  private loadSuggestions(): void {
    this.suggestions = this.actuality.suggestions;
    this.suggestions.forEach(suggestion => {
      suggestion.typeArticle = TypeArticle.SUGGESTION;
    });
    
    this.suggestionService.setupSuggestions(this.suggestions,this.actuality,TypeArticle.ACTUALITE).subscribe(
      (suggestions) => {
        this.suggestions = suggestions;
      },
      (error) => {
        console.error('Error fetching suggestions', error);
      }
    );
  }


  closeNewsletter($event: any): void {
    this.isOpen = $event;
  }
  
  setPopupStatus(isOpen : boolean): void {
    this.isOpenPopup = isOpen;
  }

}
