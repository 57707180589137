<footer class="main-footer">
    <div class="main-footer__container">
        <div class="main-footer__content">
            <!-- Register to the newsletter -->
            <div class="main-footer__register">
                <p class="register__title title">Restez informé!</p>
                <p class="register__text">Inscrivez-vous et recevez notre newsletter directement dans votre boîte mail
                </p>
                <form class="register__form" #f="ngForm" (ngSubmit)="onSubmit(f)">
                    <label for="email" class="register__input-wrapper">
                        <input id="email" class="register__input"
                            [class.register__input_status_error]="f.submitted && (email.errors || emailFound)"
                            type="email" name="email" placeholder="Renseignez votre email" #email="ngModel" ngModel
                            pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" required>
                        <span class="sr-only">Votre email août</span>
                    </label>
                    <ng-container *ngIf=" f.submitted && email.value.length === 0 && email.errors && !emailFound">
                        <p class="register__input-feedback">Veuillez renseigner votre adresse email</p>
                    </ng-container>
                    <ng-container *ngIf=" f.submitted && email.value.length !== 0 && email.errors && !emailFound">
                        <p class="register__input-feedback">Veuillez renseigner une adresse email valide
                            (mon-email@exemple.fr)</p>
                    </ng-container>

                    <button type="submit" area-label="s'inscrire" class="register__submit">S’inscrire</button>
                </form>
            </div>
             <!-- Links -->
             <div class="categories__container">
                <div class="categories__wrapper">
                    <div class="categorie">
                        <p class="categorie__title title">Je souhaite</p>
                        <ul class="categorie__links">
                            <li>
                                <a [routerLink]="['/decouvrir-la-deeptech/.']" class="categorie__link">Découvrir la Deeptech</a>
                            </li>
                            <li>
                                <a href="" class="categorie__link">Rejoindre une startup</a>
                            </li>
                            <li>
                                <a href="" class="categorie__link">Développer mon projet</a>
                            </li>
                            <li>
                                <a href="" class="categorie__link">Investir dans la deeptech</a>
                            </li>
                            <li>
                                <a href="" class="categorie__link">Accompagner un projet</a>
                            </li>
                        </ul>
                    </div>
                    <div class="categorie">
                        <p class="categorie__title title">Services</p>
                        <ul class="categorie__links">
                            <li>
                                <a [routerLink]="['/tandem/.']" class="categorie__link">Offres Tandem</a>
                            </li>
                            <li>
                                <a [routerLink]="['/devenir-advisor/.']" class="categorie__link">Offres Tango</a>
                            </li>
                            <li>
                                <a href="https://observatoire.lesdeeptech.fr/jobs/f/all_slug_locations/anyof_france/tags/anyof_observatoire%20deeptech"
                                target="_blank" rel="noopener noreferrer" class="categorie__link">Offres Jobboard</a>
                            </li>
                            <li>
                                <a  href="https://observatoire.lesdeeptech.fr/dashboard?applyDefaultFilters=true"
                                target="_blank" rel="noopener noreferrer" class="categorie__link">Observatoire Deeptech</a>
                            </li>
                            <li>
                                <a [routerLink]="['/me-former/.']" class="categorie__link">Formations</a>
                            </li>
                            <li>
                                <a href="https://bpifrance-creation.fr/je-cree-mon-pass-crea" target="_blank"
                                rel="noopener noreferrer" class="categorie__link">Pass Créa Deeptech</a>
                            </li>
                            <li>
                                <a [routerLink]="['/communaute-startups-deeptech-france/.']" class="categorie__link">Inscription Communauté d’entrepreneur</a>
                            </li>
                            <li>
                                <a [routerLink]="['/euroquity/.']" class="categorie__link">Inscription EuroQuity</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="categories__wrapper">
                    <div class="categorie">
                        <p class="categorie__title title">Explorer</p>
                        <ul class="categorie__links">
                            <li><a [routerLink]="['/actualites-ressources/.']" class="categorie__link">Actualités et ressources</a></li>
                            <li><a [routerLink]="['/atlas-de-la-deeptech/.']" class="categorie__link">Atlas de la Deeptech</a></li>
                            <li><a [routerLink]="['/energie/.']" class="categorie__link">Énergie et Greentech</a></li>
                            <li><a [routerLink]="['/agritech-foodtech/.']" class="categorie__link">Agritech et Foodtech</a></li>
                            <li><a [routerLink]="['/IA-software/.']" class="categorie__link">Digital : IA et Ordinateur quantique</a></li>
                            <li><a [routerLink]="['/biotech/.']" class="categorie__link">Biotech, Medtech et E-santé</a></li>
                            <li><a [routerLink]="['/industrie/.']" class="categorie__link">Industrie 4.0 : Robotique et IoT</a></li>
                            <li><a href="" class="categorie__link">Ressources à télécharger</a></li>
                            <li><a href="" class="categorie__link">FAQ</a></li>
                            <li><a [routerLink]="['/envie-d-entreprendre/glossaire/.']" class="categorie__link">Glossaire</a></li>
                        </ul>
                    </div>
                    <div class="categorie">
                        <p class="categorie__title title">A propos</p>
                        <ul class="categorie__links">
                            <li><a  [routerLink]="'/qui-sommes-nous.html'" class="categorie__link">Qui sommes-nous?</a></li>
                            <li><a href="" class="categorie__link">Presse</a></li>
                            <li><a [routerLink]="'/contact.html'" class="categorie__link">Nous contacter</a></li>
                        </ul>
                        <div class="social-media">
                            <p class="categorie__title title">Suivez-nous :</p>
                            <ul class="social-media__list">
                                <li>
                                    <a href="https://fr-fr.facebook.com/bpifrance/" class="social-media__link" target="_blank"
                                        rel="noopener noreferrer">
                                        <dep-icon [icon]="'icon-facebook'" class="icon-social" ></dep-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/Bpifrance" class="social-media__link" target="_blank"
                                        rel="noopener noreferrer">
                                        <dep-icon [icon]="'icon-twitter'" class="icon-social" ></dep-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/c/bpifrance/"
                                        class="social-media__link" target="_blank" rel="noopener noreferrer">
                                        <dep-icon [icon]="'icon-youtube'" class="icon-social" ></dep-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/showcase/les-deeptech-bpifrance/"
                                        class="social-media__link" target="_blank" rel="noopener noreferrer">
                                        <dep-icon [icon]="'icon-linkedin'" class="icon-social" ></dep-icon>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
             </div>
        </div>
        <div class="main-footer__logos">
            <ul class="logos__list">
                <li >
                  <img src="/assets/images/redesign-v2021/footer/france2030.png" class='logos__logo' alt="France 2030">
                </li>
                <li >
                  <a href="https://lafrenchtech.com/fr/" target="_blank" rel="noopener noreferrer">
                    <img src="/assets/images/redesign-v2021/footer/french-tech@2x.png" class='logos__logo' alt="La French Tech">
                  </a>
                </li>
                <li >
                  <img src="/assets/images/redesign-v2021/footer/gouvernement@2x.png" class='logos__logo' alt="Gouvernement">
                </li>
                <li>
                  <a href="https://www.bpifrance.fr/" target="_blank" rel="noopener noreferrer">
                    <img src="/assets/images/v-2024/logos/logo-bpifrance.svg" class='logos__logo' alt="Bpifrance">
                  </a>
                </li>
              </ul>
        </div>
    </div>
    <div class="main-footer__infos">
        <p>Copyright © {{date}} Bpifrance.</p>
        <ul class="links">
            <li>
                <a href="./assets/docs/Mentions-legales-du-site-Lesdeeptech.pdf" target="_blank" class="links__item"
                    rel="noopener noreferrer">Mentions légales</a>
            </li>
            <li>
                <a href="./assets/docs/CGU lesdeeptech.fr site vitrine v2.pdf" target="_blank" class="links__item"
                    rel="noopener noreferrer"><abbr title="Conditions générales d’utilisation">CGU</abbr></a>
            </li>
            <li>
                <a href="./assets/docs/protection-des-donnees-espace-publique.pdf" target="_blank" class="links__item"
                    rel="noopener noreferrer">Protection des données</a>
            </li>
            <li>
                <button type="button" class="links__item" area-label="cookies"
                    onclick="tC.privacyCenter.showPrivacyCenter(); return false">Gestion des cookies</button>
            </li>
            
            <!-- A Cacher temporairement -----------------------------------
            <li>
                <a href="" target="_blank" class="links__item"
                    rel="noopener noreferrer">Plan du site</a>
            </li>
            <li>
                <a href="" target="_blank" class="links__item"
                    rel="noopener noreferrer">Accessibilité : non conforme</a>
            </li>
            -->
        </ul>
    </div>
</footer>