<header class="main-header">
  <!-- Logo, link to homepage -->
  <section class="main-header__logo">
    <a routerLink="/" [routerLinkActive]="'is-active'" [routerLinkActiveOptions]="{exact:true}"
      (click)="toggleSelectedMenuItem(''); closeMenu()" aria-label="Accueil" ariaCurrentPage>
      <img src="/assets/images/deep-tech/Logo-Lesdeeptech-bleu.png" alt="Les deeptech">
    </a>
  </section>

  <!-- Main navigation -->
  <nav id="main-nav" class="main-nav" [class.is-open]="menuIsOpen" aria-label="Menu principal"
    (clickOutside)="toggleSelectedMenuItem('')">
    <ul class="main-nav__level-1">

      <!-- First level item -->
      <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'" *featureToggle="'!enableMenuScroll'">
        <a class="main-nav__level-1-link" [routerLink]="['/decouvrir-la-deeptech/.']"
          (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
          <span>Qu’est-ce que la deeptech ?</span>
        </a>
      </li>
      <!-- First level item with a second level navigation -->
      <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'" *featureToggle="'enableMenuScroll'">
        <button class="main-nav__level-1-link" [class.is-expanded]="selectedItem.startsWith('quest-ce-que-deeptech')"
          [attr.aria-expanded]="selectedItem.startsWith('quest-ce-que-deeptech') ? true : false"
          aria-controls="quest-ce-que-deeptech_level-2" (click)="toggleSelectedMenuItem('quest-ce-que-deeptech')">
          <span>Qu’est-ce que la deeptech&nbsp;?</span>
          <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon"></dep-icon>
          <dep-icon [icon]="'icon-arrow_forward_ios'" class="mobile-icon"></dep-icon>
        </button>

        <!-- Second level of navigation -->
        <ul id="quest-ce-que-deeptech_level-2" class="main-nav__level-2"
          [class.is-visible]="selectedItem.startsWith('quest-ce-que-deeptech')">
          <li class="main-nav__level-2-back">
            <button class="main-nav__level-2-back-button"
              [attr.aria-expanded]="selectedItem.startsWith('quest-ce-que-deeptech') ? true : false"
              aria-controls="quest-ce-que-deeptech_level-2" (click)="toggleSelectedMenuItem('quest-ce-que-deeptech')">
              <dep-icon [icon]="'icon-arrow_back'" class="icon"></dep-icon>
              <span>Qu’est-ce que la deeptech&nbsp;?</span>
            </button>
          </li>

          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <a class="main-nav__level-2-link" [routerLink]="['/decouvrir-la-deeptech/.']"
              (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
              <span>Découvrir la deeptech</span>
            </a>
          </li>
        </ul>
      </li>

      <!-- First level item -->
      <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
        <a class="main-nav__level-1-link" [routerLink]="['/atlas-de-la-deeptech/.']"
          (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
          <span>Atlas de la Deeptech</span>
        </a>
      </li>

      <!-- First level item with a second level navigation -->
      <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
        <button class="main-nav__level-1-link" [class.is-expanded]="selectedItem == 'nos-services'"
          [attr.aria-expanded]="selectedItem == 'nos-services' ? true : false" aria-controls="nos-services_level-2"
          (click)="toggleSelectedMenuItem('nos-services')">
          <span>Nos services</span>
          <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon"></dep-icon>
          <dep-icon [icon]="'icon-arrow_forward_ios'" class="mobile-icon"></dep-icon>
        </button>

        <!-- Second level of navigation -->
        <ul id="nos-services_level-2" class="main-nav__level-2" [class.is-visible]="selectedItem == 'nos-services'">
          <li class="main-nav__level-2-back">
            <button class="main-nav__level-2-back-button"
              [attr.aria-expanded]="selectedItem == 'nos-services' ? true : false" aria-controls="nos-services_level-2"
              (click)="toggleSelectedMenuItem('nos-services')">
              <dep-icon [icon]="'icon-arrow_back'" class="icon"></dep-icon>
              <span>Nos services</span>
            </button>
          </li>
          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <a class="main-nav__level-2-link" [routerLink]="['/communaute-startups-deeptech-france/.']"
              (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
              <span>Communauté d'entrepreneurs</span>
            </a>
          </li>

          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <a class="main-nav__level-2-link" [routerLink]="['/tandem/.']"
              (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
              <span>Devenir Associé avec Tandem</span>
            </a>
          </li>

          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <a class="main-nav__level-2-link" [routerLink]="['/devenir-advisor/.']"
              (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
              <span>Devenir Advisor avec Tango</span>
            </a>
          </li>

          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <a class="main-nav__level-2-link" href="https://observatoire.lesdeeptech.fr/jobs/f/tags/anyof_observatoire%20deeptech"
            target="_blank" rel="noopener noreferrer" (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
              <span class="item-icon">Jobboard <dep-icon [icon]="'icon-open_in_new'"></dep-icon></span>
            </a>
          </li>

          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <a class="main-nav__level-2-link" [routerLink]="['/euroquity/.']"
              (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
              <span>Rencontres investisseurs</span>
            </a>
          </li>

          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <a class="main-nav__level-2-link" [routerLink]="['/me-former/.']"
              (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
              <span>Formations</span>
            </a>
          </li>
        </ul>
        <!-- END of second level of navigation -->
      </li>

      <!-- First level item -->
      <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
        <a class="main-nav__level-1-link" [routerLink]="['/envie-d-entreprendre/.']"
          (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
          <span>Envie d'entreprendre</span>
        </a>
      </li>

      <!-- First level item with a second level navigation -->
      <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
        <button class="main-nav__level-1-link" [class.is-expanded]="selectedItem.startsWith('rester-informe')"
          [attr.aria-expanded]="selectedItem.startsWith('rester-informe') ? true : false"
          aria-controls="rester-informe_level-2" (click)="toggleSelectedMenuItem('rester-informe')">
          <span>Rester informé</span>
          <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon"></dep-icon>
          <dep-icon [icon]="'icon-arrow_forward_ios'" class="mobile-icon"></dep-icon>
        </button>

        <!-- Second level of navigation -->
        <ul id="rester-informe_level-2" class="main-nav__level-2"
          [class.is-visible]="selectedItem.startsWith('rester-informe')">
          <li class="main-nav__level-2-back">
            <button class="main-nav__level-2-back-button"
              [attr.aria-expanded]="selectedItem.startsWith('rester-informe') ? true : false"
              aria-controls="rester-informe_level-2" (click)="toggleSelectedMenuItem('rester-informe')">
              <dep-icon [icon]="'icon-arrow_back'" class="icon"></dep-icon>
              <span>Rester informé</span>
            </button>
          </li>
          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <a class="main-nav__level-2-link" [routerLink]="['/actualites-ressources/.']"
              (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
              <span>Actualités et ressources</span>
            </a>
          </li>
          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <button class="main-nav__level-2-link" [class.is-expanded]="selectedItem == 'rester-informe_secteurs'"
              [attr.aria-expanded]="selectedItem == 'rester-informe_secteurs' ? true : false"
              aria-controls="rester-informe_secteurs_level-3"
              (click)="toggleSelectedMenuItem('rester-informe_secteurs', 'rester-informe')">
              <span>Secteurs d'activité</span>
              <dep-icon [icon]="'icon-arrow_forward_ios'" class="icon"></dep-icon>
            </button>

            <!-- Third level of navigation -->
            <ul id="rester-informe_secteurs_level-3" class="main-nav__level-3"
              [class.is-visible]="selectedItem == 'rester-informe_secteurs'">
              <li class="main-nav__level-3-back">
                <button class="main-nav__level-3-back-button"
                  [attr.aria-expanded]="selectedItem == 'rester-informe_secteurs' ? true : false"
                  aria-controls="rester-informe_secteurs_level-3"
                  (click)="toggleSelectedMenuItem('rester-informe_secteurs', 'rester-informe')">
                  <dep-icon [icon]="'icon-arrow_back'" class="icon"></dep-icon>
                  <span>Secteurs d'activité</span>
                </button>
              </li>

              <li class="main-nav__level-3-item" [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{exact: true}">
                <a class="main-nav__level-3-link" [routerLink]="['/agritech-foodtech/.']"
                  (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
                  <span>Agritech et Foodtech</span>
                </a>
              </li>

              <li class="main-nav__level-3-item" [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{exact: true}" >
                <a class="main-nav__level-3-link" [routerLink]="['/biotech/.']"
                  (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
                  <span>Biotech</span>
                </a>
              </li>

              <li class="main-nav__level-3-item" [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{exact: true}">
                <a class="main-nav__level-3-link" [routerLink]="['/cybersecurite/.']"
                  (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
                  <span>Cybersécurité</span>
                </a>
              </li>

              <li class="main-nav__level-3-item" [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{exact: true}">
                <a class="main-nav__level-3-link" [routerLink]="['/energie/.']"
                  (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
                  <span>Energie</span>
                </a>
              </li>

              <li class="main-nav__level-3-item" [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{exact: true}">
                <a class="main-nav__level-3-link" [routerLink]="['/IA-software/.']"
                  (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
                  <span>IA et Software</span>
                </a>
              </li>

              <li class="main-nav__level-3-item" [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{exact: true}" >
                <a class="main-nav__level-3-link" [routerLink]="['/industrie/.']"
                  (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
                  <span>Industrie 4.0</span>
                </a>
              </li>

              <li class="main-nav__level-3-item" [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{exact: true}">
                <a class="main-nav__level-3-link" [routerLink]="['/medtech/.']"
                  (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
                  <span>Medtech</span>
                </a>
              </li>

              <li class="main-nav__level-3-item" [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{exact: true}">
                <a class="main-nav__level-3-link" [routerLink]="['/mobilite-ville-durable/.']"
                  (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
                  <span>Mobilité et ville durable</span>
                </a>
              </li>

              <li class="main-nav__level-3-item" [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{exact: true}" >
                <a class="main-nav__level-3-link" [routerLink]="['/quantique/.']"
                  (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
                  <span>Quantique</span>
                </a>
              </li>

              <li class="main-nav__level-3-item" [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{exact: true}" >
                <a class="main-nav__level-3-link" [routerLink]="['/spacetech/.']"
                  (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
                  <span>Spacetech</span>
                </a>
              </li>
            </ul>
            <!-- END of third level of navigation -->
          </li>

          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <a class="main-nav__level-2-link" [routerLink]="['/evenements/.']"
              (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
              <span>Evènements</span>
            </a>
          </li>

          <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
            <a class="main-nav__level-2-link" [routerLink]="['/concours-appels-a-projets/.']"
              (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
              <span>Appels à projets et concours</span>
            </a>
          </li>
        </ul>
        <!-- END of second level of navigation -->
      </li>

      <!-- First level item (lighter color) -->
      <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'"
  >
        <a class="main-nav__level-1-link main-nav__level-1-link"
          href="https://observatoire.lesdeeptech.fr/dashboard?applyDefaultFilters=true" (click)="toggleSelectedMenuItem(''); closeMenu()"
          ariaCurrentPage>
          <div class="obs_container">
          <span>Observatoire</span> 
          <img src="/assets/images/icones/icon-new.png" alt="icon-new" class="icon-new customised" />
         </div>
        </a>
      </li>

    <div class="buttons-container"> 
       <!-- Search Bar / Desktop  -->
       <div class="btn-search__container" [routerLinkActive]="'is-active'" >
        <button #searchButton class="btn-search" aria-label="search" (click)="showSearchBar()" id="searchButton" >
           Recherche
           <img src="/assets/images/icones/search.png" alt="search icon" id="iconSearch"/>
        </button>
      </div>
      <dep-search-bar [searchBar]="searchBar" id="search_bar"></dep-search-bar>
      <!-- button authentification CIAM -->
      <ul class="btn-login__container" *featureToggle="'enableLogin'">
        <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
          <button class="btn-login" [routerLink]="['/mon-espace-partenaire/.']"
             (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
             <span *ngIf="!authentified" class="ds-btn ds-btn--primary btn-cnx" (mouseover)="showTooltip()" (mouseleave)="hideTooltip()" >Accès Partenaires</span>
             <span *ngIf="authentified" class="ds-btn ds-btn--primary" >Mon espace</span>
          </button>
          <p class="tooltip" *ngIf="isTooltip">Accès réservé aux structures <br> accompagnatrices de startups Deeptech !</p>
        </li>
      </ul>
      <!-- First level item / btn EN -->
      <ul class="btn-en__container">
        <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
          <button class="main-nav__level-1-link btn-en" [routerLink]="['/international-page/.']"
            (click)="toggleSelectedMenuItem(''); closeMenu()" ariaCurrentPage>
            <span class="btn-en__color">EN</span>
          </button>
        </li>
      </ul>
    </div>
    </ul>
  </nav>
  
<div class="nav-bar__mobile">
  <!-- Search Bar - Mobile device //// TEMPORARILY HIDDEN ////  -->
  <div>
    <button class="btn-search" aria-label="search" (click)="showSearchBar();$event.stopPropagation()">
      <img  *ngIf="!searchBar" src="/assets/images/icones/search.png" alt="search icon" />
      <img  *ngIf="searchBar" src="/assets/images/icones/cancel.svg" alt="cancel icon" id="cancel"/>
    </button>
    <dep-search-bar [searchBar]="searchBar" (click)="$event.stopPropagation()"></dep-search-bar>
  </div>

  <!-- Button to toggle the navigation visibility on mobile devices -->
  <button class="main-header__nav-button" (click)="toggleMenu()" aria-label="Menu"  (click)="cancelSearchBar()">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0L32 0 32 32 0 32z" transform="translate(-311 -22) translate(311 22)" />
        <path fill="#1C2463"
          d="M5.333 24h21.334C27.4 24 28 23.4 28 22.667c0-.734-.6-1.334-1.333-1.334H5.333c-.733 0-1.333.6-1.333 1.334C4 23.4 4.6 24 5.333 24zm0-6.667h21.334c.733 0 1.333-.6 1.333-1.333s-.6-1.333-1.333-1.333H5.333C4.6 14.667 4 15.267 4 16s.6 1.333 1.333 1.333zM4 9.333c0 .734.6 1.334 1.333 1.334h21.334c.733 0 1.333-.6 1.333-1.334C28 8.6 27.4 8 26.667 8H5.333C4.6 8 4 8.6 4 9.333z"
          transform="translate(-311 -22) translate(311 22)" />
      </g>
    </svg>
  </button>
</div>
</header>