<header class="main-header">
    <div class="main-header__container">
        <!-- Logo, link to homepage -->
        <section class="main-header__logo">
            <a [routerLink]="['/']" (click)="getSelectedItem(''); closeMenu()" aria-label="Accueil">
                <img src="/assets/images/v-2024/logos/logo-deeptech.svg" alt="logo Les deeptech" class="customised">
            </a>
        </section>
        <!-- main navigation -->
        <nav id="main-nav" class="main-nav" aria-label="Menu principal"
            (clickOutside)="getSelectedItem('');closeMenu()">
            <!-- Profil : menu -->
            <ul>
                <li [routerLinkActive]="'is-active'">
                    <button class="main-nav__button" aria-controls="Profil"
                        (click)="getSelectedItem('Profil');toggleMenu('Profil');">
                        <span [class.is-active-parent]="activeParentItem === 'Profil'">Profil
                            <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
                                [class.rotate]="menuStates['Profil'] && selectedItem == 'Profil'"></dep-icon>
                        </span>
                    </button>
                </li>
                <!-- Profil : sub-menu -->
                <div class="main-nav__sub-menu" *ngIf="menuStates['Profil']" [class.visible]="selectedItem == 'Profil'">
                    <ul class="main-nav__sub-menu-list">
                        <li [routerLinkActive]="'is-active'">
                            <a class="main-nav__sub-menu-link" [routerLink]="['/...']" (click)="getSelectedItem('')"
                                ariaCurrentPage>
                                Intéressé par la Deeptech
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a class="main-nav__sub-menu-link" [routerLink]="['/...']" (click)="getSelectedItem('')"
                                ariaCurrentPage>
                                Rejoindre une startup
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a class="main-nav__sub-menu-link" [routerLink]="['/...']" (click)="getSelectedItem('')"
                                ariaCurrentPage>
                                Porteur de projet
                            </a>
                        </li>
                    </ul>
                </div>
            </ul>
            <!-- Services : menu -->
            <ul>
                <li [routerLinkActive]="'is-active'">
                    <button class="main-nav__button" aria-controls="v"
                        (click)="getSelectedItem('Services');toggleMenu('Services');">
                        <span [class.is-active-parent]="activeParentItem === 'Services'">Services
                            <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
                                [class.rotate]="menuStates['Services'] && selectedItem == 'Services'"></dep-icon>
                        </span>
                    </button>
                </li>
                <!-- Services : sub-menu -->
                <div class="main-nav__sub-menu main-nav__sub-menu--large" *ngIf="menuStates['Services']"
                    [class.visible]="selectedItem == 'Services'">
                    <div class="main-nav__sub-menu-wrapper">
                        <!-- Recruter -->
                        <ul class="main-nav__sub-menu-list">
                            <span class="main-nav__sub-menu-title">Recruter</span>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" [routerLink]="['/tandem/.']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    Tandem
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" [routerLink]="['/devenir-advisor/.']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    Tango
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link"
                                    href="https://observatoire.lesdeeptech.fr/jobs/f/all_slug_locations/anyof_france/tags/anyof_observatoire%20deeptech"
                                    target="_blank" rel="noopener noreferrer" (click)="getSelectedItem('')"
                                    ariaCurrentPage>
                                    Jobboard
                                </a>
                            </li>
                        </ul>
                        <!-- Créer -->
                        <ul class="main-nav__sub-menu-list">
                            <span class="main-nav__sub-menu-title">Créer</span>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" href="https://bpifrance-creation.fr/je-cree-mon-pass-crea" target="_blank"
                                rel="noopener noreferrer"  (click)="getSelectedItem('')"
                                    ariaCurrentPage>
                                    Pass Créa Deeptech
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="ds-btn ds-btn--secondary main-nav__sub-menu-btn" [routerLink]="['/...']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    Voir tous les services
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="main-nav__sub-menu-wrapper">
                        <!-- S’informer -->
                        <ul class="main-nav__sub-menu-list">
                            <span class="main-nav__sub-menu-title">S’informer</span>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link"
                                    href="https://observatoire.lesdeeptech.fr/dashboard?applyDefaultFilters=true"
                                    (click)="getSelectedItem('')" target="_blank" rel="noopener noreferrer"
                                    ariaCurrentPage>
                                    Observatoire Deeptech
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" [routerLink]="['/me-former/.']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    Formations
                                </a>
                            </li>
                        </ul>
                        <!-- Rencontrer / Accélérer -->
                        <ul class="main-nav__sub-menu-list">
                            <span class="main-nav__sub-menu-title">Rencontrer / Accélérer</span>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link"
                                    [routerLink]="['/communaute-startups-deeptech-france/.']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    Communauté d’entrepreneur
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" [routerLink]="['/euroquity/.']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    EuroQuity : Rencontres investisseurs
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </ul>
            <!-- Actualités et ressources : menu -->
            <ul>
                <li [routerLinkActive]="'is-active'">
                    <button class="main-nav__button" aria-controls="actualities"
                        (click)="getSelectedItem('actualities');toggleMenu('actualities');">
                        <span [class.is-active-parent]="activeParentItem === 'actualities'">Actualités et ressources
                            <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
                                [class.rotate]="menuStates['actualities'] && selectedItem == 'actualities'"></dep-icon>
                        </span>
                    </button>
                </li>
                <!-- Actualités et ressources : sub-menu -->
                <div class="main-nav__sub-menu main-nav__sub-menu--large" *ngIf="menuStates['actualities']"
                    [class.visible]="selectedItem == 'actualities'">
                    <div class="main-nav__sub-menu-wrapper">
                        <!-- Recruter -->
                        <ul class="main-nav__sub-menu-list">
                            <span class="main-nav__sub-menu-title">Agenda</span>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" [routerLink]="['/evenements/.']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    Événements
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" [routerLink]="['/concours-appels-a-projets/.']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    Appels à projet et concours
                                </a>
                            </li>
                        </ul>
                        <!-- Actualités -->
                        <ul class="main-nav__sub-menu-list">
                            <span class="main-nav__sub-menu-title">Actualités</span>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" [routerLink]="['/actualites/.']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    Articles
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" [routerLink]="['/breves/.']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    Brêves
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" [routerLink]="['/tribunes/.']"
                                    (click)="getSelectedItem('')" ariaCurrentPage>
                                    Tribunes
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="main-nav__sub-menu-wrapper">
                        <!-- Explorer -->
                        <ul class="main-nav__sub-menu-list">
                            <span class="main-nav__sub-menu-title">Explorer</span>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" (click)="getSelectedItem('')"
                                    [routerLink]="['/decouvrir-la-deeptech/.']" ariaCurrentPage>
                                    C’est quoi la Deeptech ?
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" [routerLink]="['/...']" (click)="getSelectedItem('')"
                                    ariaCurrentPage>
                                    Secteurs d’activité
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" (click)="getSelectedItem('')"
                                    [routerLink]="['/atlas-de-la-deeptech/.']" ariaCurrentPage>
                                    Atlas de la Deeptech
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" (click)="getSelectedItem('')"
                                    [routerLink]="['/etudes/.']" ariaCurrentPage>
                                    Études
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" (click)="getSelectedItem('')" [routerLink]="['/...']"
                                    ariaCurrentPage>
                                    Startup stories
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" (click)="getSelectedItem('')" [routerLink]="['/...']"
                                    ariaCurrentPage>
                                    Ressources à télécharger
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" (click)="getSelectedItem('')"
                                    [routerLink]="['/videos/.']" ariaCurrentPage>
                                    Vidéos
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" (click)="getSelectedItem('')"
                                    [routerLink]="['/podcasts/.']" ariaCurrentPage>
                                    Podcasts
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" (click)="getSelectedItem('')" [routerLink]="['/...']"
                                    ariaCurrentPage>
                                    FAQ
                                </a>
                            </li>
                            <li [routerLinkActive]="'is-active'">
                                <a class="main-nav__sub-menu-link" (click)="getSelectedItem('')" [routerLink]="['/envie-d-entreprendre/glossaire/.']"
                                    ariaCurrentPage>
                                    Glossaire
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </ul>
            <ul>
                <li [routerLinkActive]="'is-active'">
                    <a class="main-nav__button"
                        href="https://observatoire.lesdeeptech.fr/dashboard?applyDefaultFilters=true"
                        (click)="getSelectedItem('')" target="_blank" rel="noopener noreferrer" ariaCurrentPage>
                        <span>Observatoire Deeptech</span>
                    </a>
                </li>
            </ul>
        </nav>
        <!-- navbar - mobile/tablet -->
        <nav class="main-nav__mobile">
            <div>
                <button>
                    <img src="/assets/images/icones/icon-menu.svg" alt="menu icon" class="customised" />
                </button>
            </div>
        </nav>
    </div>
    <!-- main-header : right side -->
    <div class="main-header__container--right">
        <!--search bar -->
        <dep-search-bar-menu class="search-bar"></dep-search-bar-menu>
        <!-- search bar - mobile/tablet -->
        <div class="search-bar__mobile">
            <button (click)="toggleSearchBar()">
                <img src="/assets/images/icones/icon-search.svg" alt="search icon" class="customised"
                    *ngIf="!showTabletSearchBar" />
                <img src="/assets/images/icones/icon-close.svg" alt="cancel icon" class="customised"
                    *ngIf="showTabletSearchBar" />
            </button>
            <dep-search-bar-menu *ngIf="showTabletSearchBar"></dep-search-bar-menu>
        </div>
        <!-- dropdown cta - espace partenaires -->
        <div class="dropdown__container">
            <div class="dropdown" (clickOutside)="closeDropdown()">
                <button class="btn-drop" (click)="toggleDropdown()">
                    <dep-icon [icon]="'icon-keyboard_arrow_down'" class="arrow-icon"
                        [class.rotate]="showDropdown"></dep-icon>
                </button>
                <ul class="dropdown__content" *ngIf="showDropdown">
                    <li [routerLinkActive]="'is-active'">
                        <a class="dropdown__item" (click)="getSelectedItem('')" [routerLink]="['/...']" ariaCurrentPage>
                            <span>Découvrir L’Espace Partenaire</span>
                            <dep-icon [icon]="'icon-arrow_forward'" class="icon-arrow"></dep-icon>
                        </a>
                    </li>
                    <li [routerLinkActive]="'is-active'">
                        <a class="dropdown__item" (click)="getSelectedItem('')"
                            [routerLink]="['/mon-espace-partenaire/.']" ariaCurrentPage>
                            <span>Se connecter</span>
                            <dep-icon [icon]="'icon-arrow_forward'" class="icon-arrow"></dep-icon>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Btn EN -->
        <a class="btn-en" [routerLink]="['/international-page/.']">
            EN
        </a>

    </div>
</header>