import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'dep-btn-cta-article',
  templateUrl: './btn-cta-article.component.html',
  styleUrls: ['./btn-cta-article.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.2s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.2s ease-in',
              style({ opacity: 0 }))
            ]
          )
        ]
      )
    ]
  })

export class BtnCtaArticleComponent implements AfterViewInit, OnDestroy  {  
  @Input() buttonIcon: string;
  @Input() buttonText: string;
  @Input() buttonMessage: string;
  @Input() showButtonMessage: boolean;
  @Input() showReturnButton: boolean;
  @Input() isPopover: boolean;
  @Input() title: string;
  public showPopup: boolean=false;
  public urlPage : string;
  public clipboardCopySuccess = false;
  private clipboardmessageDuration = 4000;
  @Output() buttonAction: EventEmitter<any> = new EventEmitter();
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('popover') popover: ElementRef;
  public linkedinUrl: string;
  private clickListener: () => void;
  
  constructor(private location: Location,private renderer: Renderer2 , private router: Router) {
 
  }

  ngAfterViewInit() {
    this.clickListener = this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleButton.nativeElement && !this.popover.nativeElement.contains(e.target)) {
        this.showPopup = false;
        this.buttonAction.emit(this.showPopup);
      }
    });
  }

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
    }
  }
  
  ngOnInit(): void {
    this.urlPage = window.location.href;
    this.linkedinUrl = "https://www.linkedin.com/feed/?shareActive&mini=true&text="+ encodeURIComponent(this.title) + " :%0A%0A" + encodeURIComponent(window.location.href);
  }

  back(): void {
    this.location.back();
  }

  togglePopover():void{
   this.showPopup= !this.showPopup;
   this.buttonAction.emit(this.showPopup);
  }
  
  copyUrl():void{
    if(this.urlPage){
    navigator.clipboard.writeText(window.location.href)
    .then(() => {
      this.clipboardCopySuccess = true;
      this.showButtonMessage=this.clipboardCopySuccess;
      this.buttonMessage="Lien copié !";
      setTimeout(() => {
        this.clipboardCopySuccess = false;
      }, this.clipboardmessageDuration);
    })
    .catch(() => this.clipboardCopySuccess = false);
    }
  }
}