import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobOfferFilter } from 'src/app/models/job-offers/job-offer-filter.model';
import { JobOfferPartner } from 'src/app/models/job-offers/job-offer-partner.model';
import { JobOfferRequest } from 'src/app/models/job-offers/job-offer-request.model';
import { JobOffer } from 'src/app/models/job-offers/job-offer.model';
import { environment } from '../../../environments/environment';
import { GlobalURLs } from '../../GlobalURLs';

@Injectable({
  providedIn: 'root'
})
export class JobOfferService {
  private REST_API_SERVER = '';
  private JOB_OFFERS = '';
  private JOB_OFFERS_FILTER = '';
  private JOB_OFFER = '';

  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs
  ) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  getJobOffers(jobOfferRequest: JobOfferRequest): Observable<JobOfferPartner> {
    this.JOB_OFFERS = this.ressourcePath.getJobOffers();
    return this.http.post<JobOfferPartner>( this.REST_API_SERVER + this.JOB_OFFERS, jobOfferRequest);
  }

  getJobFilters(): Observable<JobOfferFilter> {
    this.JOB_OFFERS_FILTER = this.ressourcePath.getJobOffersFilterUrl();
    return this.http.get<JobOfferFilter>( this.REST_API_SERVER + this.JOB_OFFERS_FILTER);
  }

  getJobOfferDetails(id: number): Observable<JobOffer>{
    this.JOB_OFFER = this.ressourcePath.getJobOfferByIdUrl(id);
    return this.http.get<JobOffer>(this.REST_API_SERVER + this.JOB_OFFER)
  }

}
