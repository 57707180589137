import { JobOfferType } from "./job-offer-type.enum";

export class JobOfferRequest {
    cities: string[]; 
    pageNumber : number;
    pageSize : number;
    //profile : string;
    status : string;
    tags : string[];
    jobOfferType:JobOfferType;
    contractType:string;
    contractLength:string;
}