import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Video } from 'src/app/models/videos/videos.model';
import { VideosService } from 'src/app/services/videos/videos.service';
import { ServerErrorInterceptorService } from 'src/app/shared/services/servererrorinterceptor.service';
import { ActivatedRoute } from '@angular/router';
import { CookieNewsletterService } from 'src/app/services/cookie-newsletter/cookie-newsletter.service';
import { NEWSLETTER_TYPES } from 'src/app/mocks/newsletter-type/mock-newsletter.model';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { Suggestion, TypeArticle } from 'src/app/models/suggestions/suggestion.model';
import { SuggestionService } from 'src/app/services/suggestions/suggestion.service';

@Component({
  selector: 'dep-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss']
})
export class VideoDetailComponent implements OnInit, OnDestroy {

  public video: Video;

  public isOpen = true;
  public hasLiked: boolean = false;
  public suggestions:Suggestion[] = [];
    
  clipboardCopySuccess = false;
  clipboardmessageDuration = 4000;
  @Input() public selectedVideo: number;
  private title: string;
  private metaDescription: string;

  public newsletter = NEWSLETTER_TYPES[1];
  public isOpenPopup : boolean = false;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private renderer: Renderer2,
    @Inject(VideosService) private videoService: VideosService,
    private suggestionService: SuggestionService,
    private activatedRoute: ActivatedRoute,
    private cookieNewsletterService: CookieNewsletterService,
    private sharedService: SharedService,
    private serverErrorInterceptor: ServerErrorInterceptorService) {
    this.renderer.addClass(document.body, 'v2021');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.selectedVideo = params.selectedVideo.split('-')[0];
      this.getVideoById(this.selectedVideo);
    });
    this.isOpen = this.cookieNewsletterService.checkCookie(this.newsletter.cookieName);



  }

  /**
   * Get Video by id
   */

  private getVideoById(id: number): void {
    this.videoService.getVideosById(id)
      .subscribe(
        (data) => {
          this.video = data;
          this.title = data.title;
          this.metaDescription = data.altMetaDescription;
          this.titleService.setTitle(this.title);
          this.meta.addTag({
            name: 'description',
            content: this.metaDescription
          });
        },
        (error) => {
          this.serverErrorInterceptor.intercept(error)
        },
        () => {
          this.loadSuggestions();
          const imagePreview = this.sharedService.formatPreviewImage(this.video.imageUrl);
          let videoPreview = "";
          let videoPreviewEmbed = "";
          let videoPreviewCustom = "";
          this.video.contents.forEach(element => {
            if (element.videoSource) {
              videoPreview = element.videoSource;
              videoPreviewEmbed = element.videoSource.replace("/watch?v=", "/embed/");
              videoPreviewCustom = element.videoSource.replace("/watch?v=", "/v/");
            } else if (element.videoUrl) {
              videoPreview = element.videoUrl;
              videoPreviewEmbed = element.videoUrl;
              videoPreviewCustom = element.videoUrl;

            }
          });
          this.meta.addTags([
            { name: 'twitter:card', content: 'player' },
            { name: 'twitter:title', content: this.video.title },
            { name: 'twitter:url', content: videoPreview },
            { name: 'twitter:image', content: imagePreview },
            { name: 'twitter:image:src', content: imagePreview },
            { name: 'twitter:description', content: this.video.description },
            { name: "twitter:player", content: videoPreviewEmbed },
            { name: "twitter:player:width", content: "480" },
            { name: "twitter:player:height", content: "480" },
            { property: 'og:type', content: 'video' },
            { property: 'og:image', content: imagePreview },
            { property: 'og:title', content: this.video.title },
            { property: 'og:url', content: videoPreview },
            { property: 'og:video:url', content: videoPreviewEmbed },
            { property: 'og:video:secure_url', content: videoPreviewEmbed },
            { property: 'og:video:url', content: videoPreviewCustom },
            { property: 'og:video:secure_url', content: videoPreviewCustom },
            { property: 'og::description', content: this.video.description },
            { property: "og:video:width", content: "480" },
            { property: "og:video:height", content: "480" },
            { property: "og:video:type", content: "application/x-shockwave-flash" }
          ]);
        }
      );

  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'v2021');
    this.meta.removeTag("name='twitter:card'")
    this.meta.removeTag("name='twitter:title'")
    this.meta.removeTag("name='twitter:image'")
    this.meta.removeTag("name='twitter:image:src'")
    this.meta.removeTag("name='twitter:url'")
    this.meta.removeTag("name='twitter:description'")
    this.meta.removeTag("name='twitter:player:width'")
    this.meta.removeTag("name='twitter:player:height'")
    this.meta.removeTag("name='twitter:player'")
    this.meta.removeTag("property='og:type'")
    this.meta.removeTag("property='og:url'")
    this.meta.removeTag("property='og:title'")
    this.meta.removeTag("property='og:image'")
    this.meta.removeTag("property='og:video:url'")
    this.meta.removeTag("property='og:secure_url'")
    this.meta.removeTag("property='og:description'")
    this.meta.removeTag("property='og:video:width'")
    this.meta.removeTag("property='og:video:height'")
    this.meta.removeTag("property='og:video:type'")
    
  }

  public scroll(): void {
    document.getElementById('video').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  closeNewsletter($event: any): void {
    this.isOpen = $event;
  }

  public copyUrl(): void {
    navigator.clipboard.writeText(document.location.href)
      .then(() => {
        this.clipboardCopySuccess = true;
        setTimeout(() => {
          this.clipboardCopySuccess = false;
        }, this.clipboardmessageDuration);
      })
      .catch(() => this.clipboardCopySuccess = false);
  }

  likeVideo(): void {
    if (this.isLiked()) return;

    const currentLikes = Number(this.video.like) || 0;
    const updatedLikes = currentLikes + 1;
    this.video.like = updatedLikes.toString();
    this.hasLiked = true;

    const likedVideos = JSON.parse(localStorage.getItem('likedVideos') || '[]');
    likedVideos.push({ id: this.video.id, type: 'video' });
    localStorage.setItem('likedVideos', JSON.stringify(likedVideos));

    this.videoService.updateVideoLikes(this.video.id, updatedLikes).subscribe();
  }
  isLiked(): boolean {
    const likedVideos = JSON.parse(localStorage.getItem('likedVideos') || '[]');
    return likedVideos.some((item: any) => item.id === this.video?.id && item.type === 'video');
  }

  private loadSuggestions(): void {
    this.suggestions = this.video.suggestions;
    this.suggestions.forEach(suggestion => {
      suggestion.typeArticle = TypeArticle.SUGGESTION;
    });
    
    this.suggestionService.setupSuggestions(this.suggestions,this.video,TypeArticle.VIDEO).subscribe(
      (suggestions) => {
        this.suggestions = suggestions;
      },
      (error) => {
        console.error('Error fetching suggestions', error);
      }
    );
  }

  setPopupStatus(isOpen : boolean): void {
    this.isOpenPopup = isOpen;
  }

}
