import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dep-article-youtube-video',
  templateUrl: './article-youtube-video.component.html',
  styleUrls: ['./article-youtube-video.component.scss']
})
export class ArticleYoutubeVideoComponent implements OnInit {
  @Input() videoUrl: string;
  @Input() videoTitle: string;

  /*********VARIABLES *****/
  public safeUrl: any;

  constructor(private _sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {

    if (this.videoUrl.includes("list=")) {
      this.videoUrl = "https://www.youtube.com/embed/?listType=playlist&list=" + this.extractPlaylistId(this.videoUrl);
    } 
    else if (this.videoUrl.includes("youtu.be")) {
      this.videoUrl = this.videoUrl.replace("youtu.be/", "www.youtube.com/embed/");
    }
    else {
      this.videoUrl = this.videoUrl.replace("watch?v=", "embed/");
    }

    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);  
  }

  extractPlaylistId(url: string): string {
    const regex = /[?&]list=([^&]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return '';
  }
}
