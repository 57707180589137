import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { environment } from 'src/environments/environment';
import { Podcast } from '../../models/podcasts/podcast.model';
import { PodcastRequest } from 'src/app/models/podcasts/podcast-request.model';
import { Wrapper } from 'src/app/models/wrapper/wrapper.model';

@Injectable({
  providedIn: 'root'
})
export class PodcastService {
  private PODCASTS_PATH = '';
  private REST_API_SERVER = '';
  private PODCASTS_COUNT = '';

  constructor(private ressourcePath: GlobalURLs,
              private http: HttpClient) {
      this.REST_API_SERVER = environment.apiUrl;
     }

  public getPodcasts(request:PodcastRequest): Observable<Wrapper<Podcast>> {

    this.PODCASTS_PATH = this.ressourcePath.getPodcasts();
    
    let params = new HttpParams();
    if (request.start) {
      params = params.append("page", request.start);
    }
    if (request.limit) {
      params = params.append("pageSize", request.limit);
    }
    if (request.sectorId) {
      params = params.append("sectorId", request.sectorId);
    }
    if (request.regionalBranchIds && request.regionalBranchIds.length>0) {
      params = params.appendAll({"regionalBranchIds": request.regionalBranchIds});
    }
    if (request.tagIds && request.tagIds.length>0) {
      params = params.appendAll({ "tagIds": request.tagIds });
    }
    if (request.isActusRessources) {
      params = params.append("isActusRessources", request.isActusRessources);
    }
    if (request.excludeIds && request.excludeIds.length>0) {
      params = params.appendAll({"excludeIds":  request.excludeIds});
    }

    return this.http.get<Wrapper<Podcast>>(this.REST_API_SERVER + this.PODCASTS_PATH, { params });
  }

  public getPodcastsCount(isActusRessources?:boolean, tagIds?:number[]): Observable<number> {
    this.PODCASTS_COUNT = this.ressourcePath.getPodcastsCount();
    let params = new HttpParams();
    if (isActusRessources) {
      params = params.append("isActusRessources", isActusRessources);
    }
    if (tagIds) {
      params = params.appendAll({ "tagIds": tagIds });
    }
    return this.http.get<number>(this.REST_API_SERVER + this.PODCASTS_COUNT, { params });
  }

  public getPodcastById(id: number): Observable<Podcast> {
    this.PODCASTS_PATH = this.ressourcePath.getPodcastId(id);
    return this.http.get<Podcast>(this.REST_API_SERVER + this.PODCASTS_PATH);
  }
  
  public updatePodcastLikes(id: number, likes: number): Observable<any> {
    let params = new HttpParams();
    params = params.appendAll({ "like": likes });
    return this.http.put(this.REST_API_SERVER + this.PODCASTS_PATH + "?like="+likes, { params});
  }


}
